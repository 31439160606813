// INFO 
// THERE WAS A PROBLEM OF USING PINO ON CLIENT SIDE
// BECAUSE OF DUPLICATE MESSAGES. 
// DIDN'T FIND THE ROOT CAUSE

import pino from 'pino'

const pinoServerConfig = {
  browser: {
    asObject: true,    
  },
  enabled: true 
}

const pinoClientConfig = {
  browser: {
    asObject: true,    
  },
  enabled: true 
}

const consoleSilent = {
  log: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
}

const logger = (() => {
  if (typeof window === 'undefined') { // server 
    return pino(pinoServerConfig)
    // return console  // temp solution TODO 
  } else { // browser
    if (process.env.NEXT_PUBLIC_ENV === 'production') { 
      return consoleSilent
    } else {
      return console
    }
  }
})();

export default logger
