"use client"; 

import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition, Popover, Dialog } from '@headlessui/react'
import { signOut, useSession } from 'next-auth/react';

import { 
  ChevronDownIcon, 
  ChevronUpIcon,
} from '@heroicons/react/20/solid'

import { 
  Bars3Icon, 
  BellIcon, 
  XMarkIcon, 
  SquaresPlusIcon, 
  AcademicCapIcon,
  BeakerIcon, 
  RssIcon, 
  CircleStackIcon,
  ArrowsRightLeftIcon, 
  Bars4Icon, 
  Squares2X2Icon, 
  SparklesIcon, 
  PlusIcon, 
  StarIcon,
  ChatBubbleBottomCenterTextIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  ClipboardDocumentListIcon,
  QuestionMarkCircleIcon,
  AtSymbolIcon, 
  BoltIcon

} from '@heroicons/react/24/outline'


import logger from '@/lib/logger';
import { classNames } from '@/lib/helpers';
import LogoLink from '@/components/modules/header/logoLink';
import Image from 'next/image';
import SearchBar from '@/components/modules/navigation/searchBar';

const icons = {
  howItWorks: AcademicCapIcon, //BoltIcon
  automationPlatforms: CircleStackIcon, 
  integrations: SquaresPlusIcon, 
  automationTemplates: BeakerIcon, 
  apps: Squares2X2Icon, 
  categories: Bars4Icon, 
  alternatives: ArrowsRightLeftIcon, 
  featuredApps: SparklesIcon, 
  newApps: RssIcon, 
  submitApp: PlusIcon, 
  submitReview: StarIcon, 
  about: InformationCircleIcon,
  faq: QuestionMarkCircleIcon, 
  feedback: ChatBubbleBottomCenterTextIcon,
  privacy: ShieldCheckIcon, 
  terms: ClipboardDocumentListIcon,
  contacts: AtSymbolIcon,
  howToUse: BoltIcon, // BoltIcon
}


export default function SiteNavbar({ nav: navSource, userNav }) {

  logger.info(`Component.MainLayoutNavigation rendering.`)

  // Replace icon names with Components
  const nav = navSource?.map(navItem => {
    const { menu, actions, columns, ...restNavItem } = navItem    
    const replaceIcon = (section) => {
      const newSection = section?.map(({ icon, ...rest }) => ({ icon: icons[icon], ...rest }))
      return newSection
    }
    const newMenu = replaceIcon(menu)
    const newActions = replaceIcon(actions)
    // const newActions = actions?.map(({ icon, ...rest }) => ({ icon: icons[icon], ...rest }))
    const newColumns = columns?.map(({ 
      menu: colMenu, 
      actions: colActions, 
      ...colRest 
    }) => ({
      menu: replaceIcon(colMenu), 
      actions: replaceIcon(colActions), 
      ...colRest
    }))
    return {
      menu: newMenu, 
      actions: newActions, 
      columns: newColumns, 
      ...restNavItem
    }
  })

  
  const showNotifications = false 
  // const loginPath = "/auth/signin"
  const loginPath = "/earlyaccess"

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  // Search 
  const [searchText, setSearchText] = useState('')  // SearchText State
  const [searchInputState, setSearchInputState] = useState(false)  // When true, then on mobile input is activated, otherwise only icon is shown   
  

  // User
  const { data: session, status } = useSession()
  const user: any = session?.user  
  const isAuthenticated: boolean = (user?.username || user?.uid) ? true : false  
  const handleSignOut = () => {
    signOut()
  }  

 

  function UserImage() {

    const src = user?.image
    const alt = user?.name
    const name = user?.name || user?.email

    
    if (src) return (
      <img 
        src={src} 
        alt={alt} 
        className="h-8 w-8 rounded-full border-2 border-purple-900 "
        // width={size} 
        // height={size} 
      />      
    )
    
    // Can not use external image because it's not configured in our nextjs settings 
    // if (src) return (
    //   <Image 
    //     src={src}
    //     fill={true}
    //     alt={alt || ""}
    //   />
    // )
    return (
      <div className={`h-8 w-8 rounded-full bg-gray-100 border border-gray-200 flex items-center justify-center`}>
        <span className="font-bold text-gray-400">
          { user?.name ? name?.charAt(0)?.toUpperCase() : 'U' } 
          {/* flex items-center justify-center */}
        </span>
      </div>         
    )
  }


  return (
    <nav className="relative isolate z-10 bg-white border-b border-gray-200">

      <div className="mx-auto max-w-7xl px-0 sm:px-4 lg:px-8">
        <div className="flex h-16 justify-between sm:items-stretch">
          
          {/* LOGO and MENU LAPTOP */}
          <div className="flex px-2 lg:px-0">
            <div className="flex flex-shrink-0 items-center px-1.5">
              {/* <LogoLink variant="long"/> */}
              <LogoLink variant={searchInputState ? 'short' : 'long'}/>
            </div>

            <Popover.Group className="hidden lg:flex lg:ml-9 lg:gap-x-9">
              {nav.map(navItem => {
                switch (navItem?.type) {
                  case 'flyout':
                    return (
                      <Popover 
                        key={navItem.id}
                        className="inline-flex items-center border-b-2 border-transparent hover:border-crisp-500 hover:text-gray-700"
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button 
                              className={classNames(open ? 
                                "bg-gray-200" : "hover:bg-gray-100 ", 
                                "flex items-center gap-x-1 text-sm font-semibold text-gray-500 hover:text-gray-700 rounded px-2 py-1 outline-none")}
                            >
                              <span>
                                {navItem.name}
                              </span>
                              {open ? (
                                <ChevronUpIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                              )}                              
                            </Popover.Button>
      
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"  // 200
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-50"  // 150
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
                                <div className="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                                  {navItem.menu.map((item) => (
                                    <div key={item.name} className="group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50">
                                      <div className="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-crisp-600" aria-hidden="true" />
                                      </div>
                                      <a href={item.href} className="mt-6 block font-semibold text-gray-900">
                                        {item.name}
                                        <span className="absolute inset-0" />
                                      </a>
                                      <p className="mt-1 text-gray-600">
                                        {item.description}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                                {navItem?.actions?.length > 0 ? (
                                  <div className="bg-gray-50">
                                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                      <div className="grid grid-cols-3 divide-x divide-gray-900/5 border-x border-gray-900/5">
                                        {navItem?.actions?.map((item) => (
                                          item?.name ? (
                                            <a
                                              key={item.name}
                                              href={item.href}
                                              className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                                            >
                                              <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                              {item.name}
                                            </a>
                                          ) : null 
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    )
                  case 'flyoutColumns':
                    return (
                      <Popover 
                      className="inline-flex items-center border-b-2 border-transparent hover:border-crisp-500 hover:text-gray-700"
                        key={navItem.id}
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button 
                              className={classNames(open ? 
                                "bg-gray-200" : "hover:bg-gray-100 ", 
                                "flex items-center gap-x-1 text-sm font-semibold text-gray-500 hover:text-gray-700 rounded px-2 py-1 outline-none")}
                            >
                              {navItem.name}
                              {open ? (
                                <ChevronUpIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                              )}
                            </Popover.Button>
                      
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5">
                                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-6 py-10 lg:grid-cols-2 lg:px-8">
                                  <div className="grid grid-cols-2 gap-x-6 sm:gap-x-8">
                                    {navItem?.columns?.map(col => (
                                      <div key={col?.id}>
                                        <p className="text-sm font-medium leading-6 text-gray-500">
                                          {col?.name}
                                        </p>
                                        <div className="mt-6 flow-root">
                                          <div className="-my-2">
                                            {col?.menu?.map((item) => (
                                              <a
                                                key={item.name}
                                                href={item.href}
                                                className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                              >
                                                <item.icon className="h-6 w-6 flex-none text-gray-400" aria-hidden="true" />
                                                {item.name}
                                              </a>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">
                                    <p className="sr-only">
                                      Recent posts
                                    </p>
                                    {false && [{ 
                                      id: 1, 
                                      title: "",
                                      description: "", 
                                      imageUrl: "", 
                                      href: "", 
                                      date: "", 
                                      datetime: "", 
                                      category: { 
                                        title: "", 
                                        href: ""
                                      }
                                    }]?.map((post) => (
                                      <article
                                        key={post.id}
                                        className="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch"
                                      >
                                        <div className="relative flex-none">
                                          <img
                                            className="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto"
                                            src={post.imageUrl}
                                            alt=""
                                          />
                                          <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div>
                                          <div className="flex items-center gap-x-4">
                                            <time dateTime={post.datetime} className="text-sm leading-6 text-gray-600">
                                              {post.date}
                                            </time>
                                            <a
                                              href={post.category.href}
                                              className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100"
                                            >
                                              {post.category.title}
                                            </a>
                                          </div>
                                          <h4 className="mt-2 text-sm font-semibold leading-6 text-gray-900">
                                            <a href={post.href}>
                                              <span className="absolute inset-0" />
                                              {post.title}
                                            </a>
                                          </h4>
                                          <p className="mt-2 text-sm leading-6 text-gray-600">{post.description}</p>
                                        </div>
                                      </article>
                                    ))}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                      
                      
                    )
                  default:
                    return (
                      <a 
                        key={navItem.id} 
                        href={navItem.href} 
                        className="inline-flex items-center border-b-2 border-transparent  text-sm font-semibold text-gray-500 hover:border-crisp-500 hover:text-gray-700"
                      >
                        <span className="hover:bg-gray-100 rounded px-2 py-1">
                          {navItem.name}
                        </span>
                      </a>                            
                    )
                }
                                    
              })}

            </Popover.Group>
          </div>

          {/* SEARCH  */}
          <div className="flex-1 flex items-center justify-center px-0 lg:ml-6 lg:justify-end">
            <SearchBar
              placeholder='Search' 
              searchText={searchText}
              setSearchText={setSearchText}
              setSearchInputState={setSearchInputState} 
              searchInputState={searchInputState}
              showSearchButton={false}
              classContainer='max-w-lg w-full lg:max-w-xs relative'
              classInput='block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-crisp-600 focus:border-crisp-600 sm:text-sm'
              classDropdownContainer='text-sm z-10  mt-16 bg-white  overflow-auto rounded-md  max-h-56 absolute inset-x-0 top-0 border-gray-200'
            />
          </div>


          
          {/* Mobile menu button */}
          <div className="flex items-center lg:hidden">
            <button
              type="button"
              // className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              className="inline-flex items-center justify-center rounded-md p-3 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          
          {/* RIGHT SIDE (login, User Profile) - LAPTOP */}
          <div className="hidden lg:ml-4 lg:flex lg:items-center">
            
            {showNotifications && (
              <button
                type="button"
                className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-crisp-500 focus:ring-offset-2"
              >
                <span className="sr-only">
                  View notifications
                </span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            )}

            {/* Profile dropdown - LAPTOP */}
            {!isAuthenticated ? (
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <a 
                  href={loginPath} 
                  className="text-sm font-semibold leading-6 text-gray-500 hover:text-crisp-500"
                >
                  Log in <span aria-hidden="true">&rarr;</span>
                </a>
              </div>              
            ) : (
              <Menu as="div" className="relative ml-4 flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-crisp-500 focus:ring-offset-2">
                    <span className="sr-only">
                      Open user menu
                    </span>
                    <UserImage />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNav?.map(item => (
                      <Menu.Item key={item.id}>
                        {({ active }) => {
                          if (item.id === 10) {
                            return (                          
                              // Logout Link. Authenticated. Laptop.
                              <button
                                className={classNames(active ? 
                                  'bg-gray-100' : '', 
                                  'block px-4 py-2 text-sm text-left text-gray-700 w-full')
                                }
                                onClick={() => handleSignOut()}
                              >
                                {item.name}
                              </button>
                              )
                          } else {
                            return (
                              <a
                                href={item.href}
                                className={classNames(active ? 
                                  'bg-gray-100' : '', 
                                  'block px-4 py-2 text-sm text-left text-gray-700')}
                              >
                                {item.name}
                              </a>
                            )
                          }                        
                        }}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>              
            )}


          </div>
        </div>
      </div>



      {/* MOBILE MENU  */}

      <Dialog as="div" 
        className="lg:hidden" open={mobileMenuOpen} 
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          
          {/* LOGO - Mobile Popup Menu  */}
          <div className="flex items-center justify-between">
            <LogoLink variant="long"/>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">

                {nav?.map(navItem => {
                  switch (navItem?.type) {
                    case 'flyout':
                      return (
                        <Disclosure 
                          key={navItem?.id}
                          as="div" 
                          className="-mx-3"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button 
                                className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50"
                              >
                                {navItem?.name}
                                <ChevronDownIcon
                                  className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="mt-2 space-y-2">
                                {navItem?.menu?.map((item) => (
                                  <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                                  >
                                    {item.name}
                                  </Disclosure.Button>
                                ))}
                                {navItem?.actions?.map((item) => (
                                  <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-crisp-800 hover:bg-gray-100"
                                  >
                                    {item.name} <span aria-hidden="true">&rarr;</span>
                                  </Disclosure.Button>
                                ))}                                  
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    case 'flyoutColumns':
                      return (
                        <Disclosure 
                          key={navItem?.id}
                          as="div" 
                          className="-mx-3"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                {navItem?.name}
                                <ChevronDownIcon
                                  className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="mt-2 space-y-2">
                                {navItem?.columns?.map(col => col?.menu)?.flat()?.map(item => (
                                  <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                                  >
                                    {item.name}
                                  </Disclosure.Button>
                                ))}
                              </Disclosure.Panel>

                            </>
                          )}
                        </Disclosure>
                      )
                    default:
                      return (
                        <a 
                          key={navItem.id} 
                          href={navItem.href} 
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {navItem.name}
                        </a>                            
                      )
                  }
                                      
                })}
              </div>

              <div className="py-6">
                {!isAuthenticated ? (
                  <a
                    href={loginPath}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                ) : (
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                          { user?.name || user?.email || "User" }
                          <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {userNav?.map(item => {
                            if (item.id === 10) {
                              return (                          
                                // Logout Link. Authenticated. Mobile.
                                <button
                                  className="text-left block w-full rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                                    onClick={() => handleSignOut()}
                                  >
                                    {item.name}
                                </button>
                                )
                              } else {
                                return (
                                  <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                                  >
                                    {item.name}
                                  </Disclosure.Button>
                                )
                              }
                            })}
                        </Disclosure.Panel>

                      </>
                    )}
                  </Disclosure>                  
                )}

              </div>
              


            </div>
          </div>
        </Dialog.Panel>
      </Dialog>

    </nav>
  )  
}