"use client"; 

import { useEffect } from 'react';
import TagManager from 'react-gtm-module';


export default function GoogleTagManager() {
  
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER && (process.env.NEXT_PUBLIC_ENV === 'production')) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER });
    }
  }, []);

  return (<></>)
}
