
import { BoltIcon } from '@heroicons/react/20/solid'
import { classNames } from '@/lib/helpers';


export default function LogoLink(
  { variant }: 
  { variant?: string}
  ) {  

  const isProduction = process.env.NEXT_PUBLIC_ENV === 'production'

  return (
    <a href="/" className="p-0 focus:outline-none space-x-0.5">
      <span className="sr-only">
        stackreaction
      </span>
      <span className="inline  pl-0.5 text-3xl text-gray-700">
        <BoltIcon className={classNames(isProduction ? 'text-crisp-700' : 'text-red-700', 'inline h-8 w-8')}/>
      </span>
      {(variant === 'long') ? (
        <span className="inline pl-0.5 text-3xl text-gray-700">
          stackreaction
        </span>
      ) : null }

    </a>
  );
}
